import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: fit-content;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff7070;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }

    //  表用のCSS
    table {
      width: 100%;
      margin-bottom: 15px;


      td {
        padding: 5px;
        border: solid 1px rgba(200, 200, 200, 1);
        vertical-align: top;
      }
    }
  }

}

${BreakPoints.large} {
}
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0096 = (props: Props): JSX.Element => {
  const routeParam = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0089/'
  )
  const routeParam2 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0068/'
  )
  const routeParam3 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0069/'
  )
  const routeParam4 = useRouteParam('https://www.kintaicloud.jp/')

  return (
    <Wrapper>
      <ColumnH1 label="勤怠管理システムとは？導入メリットと必要機能を解説" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2024.04.25</p>
      <section>
        <img
          src="/images/column/details/c0096.jpg"
          alt="キンクラコラム画像96"
        />
        <p>
          企業規模に関わらず、勤怠管理システムを導入することにはメリットがあります。なかでも、給与計算ミスや不正防止など、人的ミス防止や業務環境の改善につながることがポイントです。
          <br />
          本記事では、企業規模に関係なく勤怠管理システムを導入するメリットと、最低限必要な機能について解説します。
        </p>
        <div id="toc1">
          <h1>中小企業が勤怠管理システムを導入する3つのメリット</h1>
        </div>
        <img
          src="/images/column/details/c0096_g01.jpg"
          alt="キンクラコラム画像96"
        />
        <p>
          中小企業や小規模事業者であっても、勤怠管理システムを導入することで得られるメリットがあります。
        </p>
        <div id="toc1-1">
          <h2>法改正に対応できる</h2>
        </div>
        <p>
          法改正だけではなく労働環境にも迅速に対応することで、企業は法的なリスクや損失を軽減できます。法改正と聞くと、労働条件に関するものを連想しがちですが、それだけではありません。例えば、近年であれば新型コロナの助成金申請のために、1時間以上の時短勤務をしていることと休業手当を支払ったことの証明が必要でした。1時間以上の時短勤務を証明するためには、それに応じた勤怠管理が必要です。法的リスクの防止だけではなく急な政府の施策に対応するためにも、勤怠管理システムの導入にはメリットがあります。
        </p>
        <div id="toc1-2">
          <h2>業務効率化が図れる</h2>
        </div>
        <p>
          勤怠管理システムは、従業員自身が出退勤の時間を打刻できるため、自動的に休憩時間や退勤時間、残業時間などが管理できます。誰が管理する場合でも、勤怠管理システムを導入せず人の手で行えばミスが発生する可能性があります。また、データを集計する時間もかってしまうでしょう。このような集計上のミスや作業時間のコスト削減が可能になり、業務効率化が図れます。
        </p>
        <div id="toc1-3">
          <h2>コスト削減につながる</h2>
        </div>
        <p>
          勤怠管理システムを導入することで時間的なコストだけではなく、人件費の削減にもつながります。業務効率化が可能になれば、残業時間が削減できるため残業代の削減につながり結果的に人件費削減になるでしょう。残業代は基本給と異なり臨時的に発生するもので、経営者や経理担当者では予測しにくい費用です。予測しにくいだけに削減できれば、企業が作成している事業計画に近づくことができます。
          <br />
          <br />
          以上の理由から、事業規模に関わらず勤怠管理システムを導入することは、事業者にとってプラスです。
        </p>
        <a href={routeParam} className="column-detail-link">
          関連記事：中小企業向け勤怠管理システムの選び方とは？おすすめシステムも紹介
        </a>
        <div id="toc2">
          <h1>勤怠管理システムに最低限必要な4つの機能</h1>
        </div>
        <img
          src="/images/column/details/c0096_g02.jpg"
          alt="キンクラコラム画像96"
        />
        <p>
          事業規模に関わらず、勤怠管理システムには最低限必要な機能があります。
        </p>
        <div id="toc2-1">
          <h2>打刻機能</h2>
        </div>
        <p>
          適切な給与計算や労働法遵守を実現するためには、欠かせない勤怠管理システムの機能です。打刻を正確に行ことで、遅刻や早退、無断欠勤などの問題にも迅速に対応でき、正確な労働時間の把握に役立ちます。労働環境改善や、ワークライフバランスの実現も可能になるでしょう。
        </p>
        <div id="toc2-2">
          <h2>シフト管理</h2>
        </div>
        <p>
          企業側にとっては労働力の最適な分配、従業員にとっては自身の希望や能力を考慮したシフト作成が可能です。従業員の希望を考慮したシフトが作成できれば、満足度が上がり生産性の向上にもつながるでしょう。従業員の満足度があれば、離職率の低下にもつながり、企業は人材確保と育成にかかるコストも削減できます。
        </p>
        <div id="toc2-3">
          <h2>自動集計</h2>
        </div>
        <p>
          人件費の実態管理や予算計画のためのデータ収集が可能です。また、正確な労働時間の把握や有給管理ができることで、労働法を遵守しているかどうかを確認できます。同時に、人が行う作業の手間を省き、ミスも削減できるため業務効率化に役立ちます。自動集計機能は、管理者が必要な情報をすぐに取得できるため、迅速な意思決定が求められる際にも役立つでしょう。
        </p>
        <div id="toc2-4">
          <h2>システム連携</h2>
        </div>
        <p>
          自社で使用している給与計算システムをはじめ、他のシステムとの連携ができれば、データの一貫性を保つことができます。システムごとに作業が途切れることがなく、ミスが発生しやすいポイントをはじめから省略できます。また、誤りが見つかった際も勤怠管理システムのみ修正すれば、あとは一元的にデータ更新ができるため、さらなるミスの防止もつながるでしょう。
          <br />
          <br />
          以上の機能があることで、勤怠管理から給与計算までスムーズに業務が進むでしょう。
        </p>
        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}
        <div id="toc3">
          <h1>勤怠管理システムを導入しなかった失敗例</h1>
        </div>
        <img
          src="/images/column/details/c0096_g03.jpg"
          alt="キンクラコラム画像96"
        />
        <p>
          「事業規模が小さいから」と勤怠管理システムを導入しなければ、結果的に後悔することもあります。
        </p>
        <a href={routeParam2} className="column-detail-link">
          関連記事：勤怠管理システムを導入していない会社で発生するリスクとは？
        </a>
        <div id="toc3-1">
          <h2>ケース1：給与計算を間違えた</h2>
        </div>
        <p>
          A社では「自社の規模が小さく手作業で給与計算ができるだろう」と判断し、勤怠管理システムは導入していませんでした。あるとき、残業時間の計算を間違いそのまま従業員へ給与を支給。従業員からの不満が生じ、企業の信頼性が損なわれる事態となりました。過信から勤怠管理システムの導入見送ったために発生した失敗事例です。
        </p>
        <div id="toc3-2">
          <h2>ケース2：手動作業が増えた</h2>
        </div>
        <p>
          B社では「今どきExcel(エクセル)があるから、出退勤の記録や集計くらい簡単にできるだろう」と勤怠管理システムを導入していませんでした。その後、従業員が年々増加し勤怠管理の作業量も増大。担当者の業務負担も増え、重要な業務に時間を割くことができず、業務効率が低下しました。手動作業を信用しすぎていたことによる失敗事例です。
        </p>
        <a href={routeParam3} className="column-detail-link">
          関連記事：手動での勤怠管理からの脱却！無料の勤怠管理システムのメリットとは？
        </a>
        <div id="toc3-3">
          <h2>ケース3：不正が見抜けなかった</h2>
        </div>
        <p>
          C社では「自分の勤務時間は自分で管理できるだろう」と勤怠管理システムを導入せず、従業員の自主性に任せていました。ある日、従業員の1人が他の従業員に変わりタイムカードを「代打ちする」という事態が発生。この不正により、人件費を適切に管理することができず、企業は利益を損なう結果となりました。従業員の自主性に過度に依存しすぎたことによる失敗事例です。
          <br />
          <br />
          以上のような失敗を防ぐためにも、勤怠管理システムを導入することは必要です。
        </p>
        <div id="toc4">
          <h1>中小企業でも勤怠管理システムの導入にはメリットがある</h1>
        </div>
        <p>
          企業規模に関わらず、勤怠管理システムを導入することには、業務効率化や不正防止などのメリットがあります。勤怠管理システムを導入しなかったことで、後悔する場面もあるでしょう。
          <br />
          また、法改正にも対応できるため結果的に事業者を守ることにもつながります。
          <br />
          <br />
          中小企業や小規模事業者にも適している勤怠管理システムに「キンクラ」があります。キンクラには、打刻機能だけではなく、ほかのシステムとの連携ができる機能も装備。勤怠管理システムを導入することで、不正防止にもつながります。
          <br />
          ぜひこの機会に、キンクラをご検討ください。
        </p>
        <a href={routeParam4} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0096
